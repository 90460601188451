
<template lang="pug">
  .service-box(:class="classes" ref="serviceBtn")
    button.btn-reset(@click="() => $emit('service-selected', service)")
      .service.text-color-2.xs-f-xsmall.sm-f-small.lg-f-xsmall.max-at-tweak.flex-fill(ref='service' :class="{ phone_only: service.phoneOnly, selected }")
        .service-inner-wrap(ref="serviceInnerWrap")
          .first-row(ref="firstRow")
            .xs-4(v-if="service.imageId")
              .a-box-xs-1x1
                .a-box-content
                  img-box(:media-id="service.imageId")
            img.image(v-else :src="service.image")

            .info
              span.ada-tooltip Select
              .name.text-color-1.bold.upper {{ service.displayName || service.name }}
              .description {{ service.description }}

            .info-icon-container
              span(v-if="quickViewModalAvailable" @click.stop="() => $emit('open-service-quick-view-modal')")
                mr-icon.xs-f-small.quick-view-icon(name="info")

          .second-row(v-if="!service.isStandIn && showPricing" ref="secondRow")
            .time(v-if="service.totalTimeRequired")
              mr-icon(name="clock" :fill="false")
              | {{ timeRequiredFormatted }}

            .price(v-if="prices.new")
              mr-icon(name="tag")
              span.old-price(v-if="prices.old") {{ prices.old }}
              | {{prices.new}}
</template>

<script>
  import { formatAppointmentTime } from '@utilities/mrVueUtils';
  import ImgBox from '@components/imgBox';

  export default {
    name: 'ServiceBox',

    components: {
      ImgBox
    },

    props: {
      service: {
        type: Object,
        required: true,
      },

      selected: {
        type: Boolean,
        default: () => false,
      },

      classes: {
        type: Array,
        default: () => ['xs-12', 'md-6', 'lg-4', 'd-flex']
      },

      showPricing: {
        type: Boolean,
        default: true
      }
    },

    computed: {
      quickViewModalAvailable() {
        return !!this.service.metadata && !!Object.keys(this.service.metadata).length;
      },

      timeRequiredFormatted() {
        return formatAppointmentTime(this.service.totalTimeRequired);
      },

      prices() {
        const { discountPrice, price, isFree } = this.service;

        if (isFree || price === 0) {
          return {
            old: price ? `$${price}` : null,
            new: 'Free',
          };
        }

        if (discountPrice) {
          return {
            old: price ? `$${price}` : null,
            new: `$${discountPrice}`,
          };
        }

        return {
          new: `$${price}`,
        };
      },
    },
  };
</script>

<style scoped lang="stylus">
  .service-box
    button
      width 100%

      .service
        text-align left
        display flex
        flex 1 1 auto
        flex-direction column
        justify-content space-between
        margin 5px
        padding 10px
        border 1px solid ui-color-5
        box-shadow 0 1px 4px 0 rgba(0, 0, 0, 0.08)
        cursor pointer
        -webkit-transition : border 500ms ease-out;
        -moz-transition : border 500ms ease-out;
        -o-transition : border 500ms ease-out;
        transition : border 500ms ease-out;

        .service-inner-wrap
          display flex
          flex-direction column

        .quick-view-icon
          color cta-color-1
          position relate

        .first-row
          display flex
          flex-direction row
          align-items stretch
          flex 1

          .image
            width 100px
            max-height 78px
            margin-right: 10px

          .info-icon-container
            padding-left 5px

          .info
            display flex
            flex-direction column
            flex 1
            align-items stretch
            overflow hidden

            .name
              line-height  1.6em
              white-space nowrap
              overflow hidden
              text-overflow ellipsis

            .description
              flex 1
              width 100%
              display inline-block
              text-overflow ellipsis
              line-height 1.6em
              overflow hidden

        .second-row
          display flex
          flex-direction row

          .time
            margin-right 10px
            display flex
            align-items center

          .price
            display flex
            align-items center

            .old-price
              color text-color-3
              text-decoration line-through
              margin-right 5px

          svg
            margin-right 2px

        &:hover, &.selected
          border 1px solid cta-color-5

          .first-row
            .info
              .name
                color cta-color-5

    @media mq-mobile
      .service
        margin 5px 15px
</style>
